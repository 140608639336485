<template>
  <v-row class="ma-2 flex-column">
    <div class="d-flex align-center justify-space-between ma-4">
      <h1 class="w-100 fz-20 font-weight-medium">Contacts ({{ contacts.length }})</h1>
      <v-btn
        color="primary"
        elevation="0"
        class="font-weight-medium w-max text-none"
        @click="$router.push({ name: 'NewContact' })"
      >
        <v-icon left>mdi-plus</v-icon>
        Create Contact
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="contacts"
      :loading="loading"
      height="calc(100vh - 215px)"
      item-key="uuid"
      class="w-100"
      :class="{ 'hide-page-arrows': itemsPerPage >= total }"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100]
      }"
      @update:options="loadContactList()"
    >
    <template v-slot:[`header.actions`]>
      <span class="mr-4">Actions</span>
    </template>
    <template v-slot:[`footer.page-text`]>
      <span class="mr-4">Total: {{ total }}</span>
      <span v-if="itemsPerPage < total">Page: {{ page }}</span>
    </template>
      <!-- <template v-slot:[`header.actions`]>
        <div class="d-flex align-center justify-end">
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="text-none d-flex align-center px-0"
                v-bind="attrs"
                v-on="on"
                @click="print"
              >
                <v-icon size="20" color="#444746">mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>Print</span>
          </v-tooltip>
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="text-none d-flex align-center px-0"
                v-bind="attrs"
                v-on="on"
                @click="exportTable"
              >
                <v-icon size="20" color="#444746">mdi-tray-arrow-up</v-icon>
              </v-btn>
            </template>
            <span>Export</span>
          </v-tooltip>
        </div>
      </template> -->
      <template v-slot:[`item.name`]="{ item }">
        {{ item.first_name }} {{ item.last_name }}
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        {{ formatPhone(item.phone_number) }}
      </template>
      <template v-slot:[`item.labels`]="{ item }">
        <div class="label-wrapper">
          <v-chip
            v-for="(label, index) in item.userLabel"
            :key="index + 'label'"
            class="fz-12 pa-2"
          >
            {{ label.label }}
          </v-chip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-end">
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :to="{ name: 'EditContact', params: { uuid: item.uuid } }"
                icon
                class="text-none d-flex align-center px-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="20" color="#444746">mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top content-class="v-tooltip--top">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="text-none d-flex align-center px-0"
                v-bind="attrs"
                v-on="on"
                @click="openDeleteModal(item)"
              >
                <v-icon size="20" color="#444746">mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Delete {{ selectedContact.first_name }} {{ selectedContact.last_name }} ({{ selectedContact.phone_number }})
          <v-spacer></v-spacer>
          <v-btn @click="closeDeleteModal" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this contact?
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeDeleteModal"
          >Cancel
          </v-btn>
          <v-btn
            :loading="loadingDelete"
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            @click="deleteSelectedContact"
          >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { formatPhone } from '../utilities/formatPhone'

export default {
  name: 'Contacts',
  data: () => ({
    loading: false,
    loadingDelete: false,
    deleteDialog: false,
    selectedContact: {},
    itemsPerPage: 50,
    page: 1,
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      /* {
        text: 'Email',
        align: 'start',
        sortable: false,
        value: 'email',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }, */
      {
        text: 'Phone Number',
        align: 'start',
        sortable: false,
        value: 'phone_number',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Labels',
        align: 'start',
        sortable: false,
        value: 'labels',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      },
      {
        text: 'Actions',
        align: 'end',
        sortable: false,
        value: 'actions',
        class: ['text-caption', 'greyBuoyant'],
        cellClass: 'greyBuoyant'
      }
    ]
  }),
  computed: {
    ...mapGetters('contacts', ['contacts', 'total'])
  },
  methods: {
    ...mapActions('contacts', ['getContacts', 'deleteContact']),
    ...mapActions(['showAlert']),
    formatPhone,
    async loadContactList() {
      try {
        this.loading = true
        await this.getContacts()
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    openDeleteModal(item) {
      this.selectedContact = item
      this.deleteDialog = true
    },
    closeDeleteModal() {
      this.selectedContact = {}
      this.deleteDialog = false
      this.loadingDelete = false
    },
    async deleteSelectedContact() {
      try {
        this.loadingDelete = true
        const res = await this.deleteContact(this.selectedContact.uuid)
        if (res) {
          this.closeDeleteModal()
          this.showAlert({ message: 'Contact was successfully deleted' })
          this.loadContactList()
        }
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      } finally {
        this.loadingDelete = false
      }
    },
    print () {},
    exportTable () {}
  }
}
</script>

<style lang="scss" scoped>
.label-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}
.hide-page-arrows ::v-deep {
  .v-data-footer__icons-before,
  .v-data-footer__icons-after {
    display: none;
  }
}
</style>
